import Store from '../../store/store';

let ACTIONS;

const DEFAULT = {
  AccessSettingsChange: false,
  AccessToUploadEmployees: false,
  DocsChange: false,
  ClosingDocsChange: false,
  Services: [],
  Reports: [],
  EditTripItemVersion: [],
  Accountant: false,
  TripChange1C: false,
  AdminChange: false,
  AggregatorChange: false,
  TariffChange: false,
  ManualTrainCancel: false,
  RemoveDocuments: false,
  IncomingOperationChange: false,
  CanManageTemplates: false,
  HotelRestrictionChange: false,
  Mice: false,
  ClosingDocumentsConstructor: false,
  TripMove: false,
  Vacation: false,
  CanSetBookingSettings: false,
};

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.STOREINIT:
      return DEFAULT;

    case ACTIONS.FEATUREFLAGS.GETALLFEATUREFLAGS: {
      return {
        ...state,
        ...action.payload,
      };
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('FeatureFlags', reducer, DEFAULT);

  const createStore = () => new Store('FeatureFlags', reducer, DEFAULT);

  return createStore();
}
