import Store from '../../store/store';

import { makeDefaultForm } from '../../utils/hotels';
import { getMoment } from '../../utils/formatDate';

import {
  DEFAULT_EC_LC_CONDITIONS,
  DEFAULT_CONDITIONS_VALIDATION,
  DEFAULT_MATCH_HOTEL_IDS,
  DEFAULT_MATCHES_HOTELS,
} from '../../constants/hotels';
import {
  GUESTS,
  VAT_VALUES,
} from '../../constants/hotelBookRequest';

let ACTIONS;

const FILE_UPLOAD_FAILED = 'Не удалось загрузить файл.';

const validation = {
  Name: '',
  Description: '',
  DescriptionEn: '',
  ShortDescription: '',
  Thumbnail: '',
  Stars: '',
  CheckinTime: '',
  CheckoutTime: '',
  Latitude: '',
  Longitude: '',
  RegionId: '',
  DistanceFromCenter: '',
  UpdateDate: '',
  formData: {
    Description: '',
    DescriptionEn: '',
  },
};

const conditionsValidation = DEFAULT_CONDITIONS_VALIDATION;

const defaultStore = {
  search: {
    value: '',
    label: '',
    suggest: [],
    init: false,
  },
  btnRequest: false,
  isNoEdit: true,
  formData: makeDefaultForm(),
  hotelsByRegion: [],
  hotelsByName: [],
  regionSuggest: [],
  isRequest: false,
  hotelLoad: true,
  validation,
  isValidForm: false,
  addedHotelId: null,
  Rates: [],
  Plans: [],
  discount: '',
  rateTypes: [],
  ratesValidation: [],
  isRatesValid: true,
  bar: false,
  contract: null,
  conditionsValidation,
  isConditionsValid: true,
  connected: false,
  hideRates: false,
  typesCohortAanda: [],
  regions: [],
  waitPreloader: false,
  autocompliteModal: false,
  hasEarlyCheckin: false,
  hasLateCheckout: false,
  cancellationDateWithFee: null,
  reservationDate: getMoment(),
  clientFreeCancellation: false,
  guestsAmount: GUESTS[0].value,
  requestStatus: null,
  isAdditionalEmail: false,
  prices: { breakfastPrice: '' },
  intercomLink: '',
  customersList: [],
  employeesList: [],
  roomTypes: [],
  selectedRooms: '',
  savedRequests: [],
  contacts: {
    email: '',
    phone: '',
    additionalEmail: '',
  },
  noContacts: {
    noEmail: false,
    noPhone: false,
  },
  hotel: {},
  hotelInfo: {
    emails: [],
    hotelId: 0,
    hotelName: '',
    phone: '',
    isSelfEmployed: false,
    worksWithVat: false,
    priceInclVat: false,
    vatRate: VAT_VALUES[0].value,
  },
  hotelRates: [
    {
      id: 0,
      roomCategory: '',
      employees: [],
      customers: [],
      price: {
        dailyPrice: '',
        commission: '',
        earlyCheckin: '',
        lateCheckout: '',
      },
      amenities: {
        hasMeal: false,
        meal: {
          include: false,
          name: '',
          category: 0,
          price: '',
        },
        hasFreeCancellation: false,
        freeCancellationDate: '',
        cancellationPenalties: [
          {
            from: '',
            base: '',
            total: '',
            additional: false,
          },
        ],
      },
      earlyCheckin: false,
      lateCheckout: false,
      checkinDate: '',
      checkinTime: '',
      checkoutDate: '',
      checkoutTime: '',
      departmentId: 0,
      projectId: 0,
      userAnalytics: {},
    },
  ],
  sendAt: null,
  conversationId: 0,
  accountId: 0,
  tripId: 0,
  cartId: 0,
  letterSubject: '',
  additionalComment: '',
  selectedRequestAnalytics: {},
  isLoading: false,
  isEditing: false,
  editedRequest: {},
  customTemplate: {
    file: null,
    uploaded: true,
    failed: false,
    error: '',
  },
  customer: {},
  requestUsers: [],
  MandatoryProject: [],
  Projects: [],
  requestUserId: '',
  customerProjects: [],
  SelectedDepartmentId: 0,
  commission: '',
  templateId: 0,
  matchProvidersId: DEFAULT_MATCH_HOTEL_IDS,
  errorMatch: '',
  matchHotels: DEFAULT_MATCHES_HOTELS,
  loadingMatchHotels: false,
  newTripFailure: false,
  newTripSuccess: false,
  hotelNotFound: false,
  cartId: null,
  accountAnalytics: [],
  isChangeMatches: false,
  comparesAllMatches: {
    Exists: false,
    Hotels: [],
    NeedGiataId: false,
    GiataCompares: null,
  },
  isHotelWithCompare: false,
  showComparesDialog: false,
  mappingGiata: null,
  loadingRestoreHotel: false,
};

const defaultHotelContract = {
  HotelId: null,
  Email: '',
  Discount: 0,
  HasVAT: false,
};

const defaultEcLcConditions = DEFAULT_EC_LC_CONDITIONS;

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.HOTELS.INPUT_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          value: action.value,
          label: action.label,
          init: action.init,
        },
      };

    case ACTIONS.HOTELS.CHANGE_FIELD:
      return { ...action.updatedState };

    case ACTIONS.HOTELS.IS_CHANGE_MATCHES: {
      return {
        ...state,
        isChangeMatches: true,
        isHotelWithCompare: false,
      };
    }

    case ACTIONS.HOTELS.UPDATE_EC_LC_CONDITIONS_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          ecLcConditions: action.ecLcConditions,
        },
        isConditionsValid: action.isConditionsValid,
        conditionsValidation: action.conditionsValidation,
      };

    case ACTIONS.HOTELS.GET_TYPES_COHORT_AANDA:
      return {
        ...state,
        typesCohortAanda: action.payload,
      };

    case ACTIONS.HOTELS.GET_HOTEL:
      return {
        ...state,
        formData: action.formData,
        hotelLoad: action.hotelLoad,
      };

    case ACTIONS.HOTELS.AUTOCOMPLITE_HOTEL:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.formData,
        },
        hotelLoad: action.hotelLoad,
      };

    case ACTIONS.HOTELS.RESET_VALIDATION:
      return {
        ...state,
        validation,
        isValidForm: true,
      };

    case ACTIONS.HOTELS.SET_AUTOCOMPLITE_MODAL:
      return {
        ...state,
        autocompliteModal: action.payload,
      };

    case ACTIONS.HOTELS.SEARCH_HOTELS:
      return {
        ...state,
        hotelsByRegion: action.hotelsByRegion,
        hotelsByName: action.hotelsByName,
        isRequest: action.isRequest,
      };

    case ACTIONS.HOTELS.DO_REQUEST:
      return {
        ...state,
        btnRequest: action.btnRequest,
      };

    case ACTIONS.HOTELS.ADD_HOTEL:
      return {
        ...state,
        addedHotelId: action.addedHotelId,
      };

    case ACTIONS.HOTELS.RESET:
      return {
        ...defaultStore,
        savedRequests: state.savedRequests,
      };

    case ACTIONS.HOTELS.UPDATE_PLANS: {
      return {
        ...state,
        Plans: action.plans,
      };
    }

    case ACTIONS.HOTELS.SET_PRELOADER: {
      return {
        ...state,
        waitPreloader: action.payload,
      };
    }

    case ACTIONS.HOTELS.DELETE_PLAN: {
      return {
        ...state,
        Plans: action.plans,
      };
    }

    case ACTIONS.HOTELS.ADD_PLAN: {
      return {
        ...state,
        Plans: [...state.Plans, action.plan],
      };
    }

    case ACTIONS.HOTELS.UPDATE_RATES: {
      const {
        rates, ratesValidation, isRatesValid,
      } = action;

      return {
        ...state,
        Rates: rates,
        ratesValidation,
        isRatesValid,
      };
    }

    case ACTIONS.HOTELS.GET_RATE_TYPES: {
      return {
        ...state,
        rateTypes: action.types,
      };
    }

    case ACTIONS.HOTELS.UPDATE_WORKS_WITH_VAT: {
      return {
        ...state,
        WorksWithVat: action.worksWithVat,
      };
    }

    case ACTIONS.HOTELS.UPDATE_PRICE_INCL_VAT: {
      return {
        ...state,
        PriceInclVat: action.priceInclVat,
      };
    }

    case ACTIONS.HOTELS.UPDATE_DISCOUNT: {
      return {
        ...state,
        discount: action.discount,
      };
    }

    case ACTIONS.HOTELS.UPDATE_BAR: {
      return {
        ...state,
        bar: action.bar,
      };
    }

    case ACTIONS.HOTELS.UPDATE_CONNECTION: {
      return {
        ...state,
        connected: action.connected,
      };
    }

    case ACTIONS.HOTELS.UPDATE_HIDE_RATES: {
      return {
        ...state,
        hideRates: action.hideRates,
      };
    }

    case ACTIONS.HOTELS.UPDATE_ACTIVATED_DATA: {
      return {
        ...state,
        activatedData: action.activatedData,
      };
    }
    case ACTIONS.HOTELS.UPDATE_CONTRACT_HOTEL: {
      return {
        ...state,
        contract: action.payload,
      };
    }
    case ACTIONS.HOTELS.UPDATE_CONTRACT_HOTEL_FIELD: {
      return {
        ...state,
        contract: {
          ...state.contract,
          [action.payload.field]: action.payload.value,
        },
      };
    }
    case ACTIONS.HOTELS.ADD_CONTRACT_HOTEL: {
      return {
        ...state,
        contract: {
          ...defaultHotelContract,
          HotelId: action.HotelId,
        },
      };
    }
    case ACTIONS.HOTELS.ADD_EC_LC_CONDITIONS: {
      return {
        ...state,
        formData: {
          ...state.formData,
          ecLcConditions: defaultEcLcConditions,
        },
        conditionsValidation: action.conditionsValidation,
        isConditionsValid: action.isConditionsValid,
      };
    }
    case ACTIONS.HOTELS.DELETE_EC_LC_CONDITIONS: {
      return {
        ...state,
        formData: {
          ...state.formData,
          ecLcConditions: null,
        },
        conditionsValidation,
        isConditionsValid: true,
      };
    }

    case ACTIONS.HOTELS.CHANGE_HAS_EARLY_CHECKIN: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_HAS_LATE_CHECKOUT: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_HAS_FREE_CANCELLATION: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_HAS_BREAKFAST: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_CLIENT_EARLY_CHECKIN: {
      return {
        ...state,
        hasEarlyCheckin: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_CLIENT_LATE_CHECKOUT: {
      return {
        ...state,
        hasLateCheckout: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_CLIENT_BREAKFAST: {
      return {
        ...state,
        hasBreakfast: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_CLIENT_FREE_CANCELLATION: {
      return {
        ...state,
        hasFreeCancellation: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_FREE_CANCELLATION_DATE: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_CANCELLATION_DATE_WITH_FEE: {
      return {
        ...state,
        cancellationDateWithFee: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_PRICES: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_CANCELLATION_PRICES: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_INTERCOM_LINK: {
      return {
        ...state,
        intercomLink: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_INTERCOM_ID: {
      return {
        ...state,
        intercomChatId: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_VAT_AMOUNT: {
      return {
        ...state,
        hotelInfo: {
          ...state.hotelInfo,
          vatRate: action.payload,
        },
      };
    }

    case ACTIONS.HOTELS.CHANGE_CHECKIN_DATE: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_CHECKIN_TIME: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_CHECKOUT_DATE: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_CHECKOUT_TIME: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_SELECTED_HOTEL: {
      return {
        ...state,
        hotelInfo: {
          ...state.hotelInfo,
          hotelName: action.payload,
        },
      };
    }

    case ACTIONS.HOTELS.CHANGE_WORKS_WITH_VAT: {
      return {
        ...state,
        hotelInfo: {
          ...state.hotelInfo,
          worksWithVat: action.payload,
        },
      };
    }

    case ACTIONS.HOTELS.CHANGE_PRICE_INC_VAT: {
      return {
        ...state,
        hotelInfo: {
          ...state.hotelInfo,
          priceInclVat: action.payload,
        },
      };
    }

    case ACTIONS.HOTELS.CHANGE_SELECTED_ROOMS: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_ADDITIONAL_EMAIL: {
      return {
        ...state,
        isAdditionalEmail: action.payload,
      };
    }

    case ACTIONS.HOTELS.GET_HOTEL_INFO: {
      return {
        ...state,
        hotelInfo: {
          ...state.hotelInfo,
          ...action.payload,
        },
      };
    }

    case ACTIONS.HOTELS.GET_ROOMS_TYPES: {
      return {
        ...state,
        roomTypes: action.payload,
      };
    }

    case ACTIONS.HOTELS.GET_SAVED_REQUESTS: {
      return {
        ...state,
        savedRequests: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_CONTACTS: {
      const { fieldName, value } = action.payload;

      return {
        ...state,
        contacts: {
          ...state.contacts,
          [fieldName]: value,
        },
      };
    }

    case ACTIONS.HOTELS.SET_NO_CONTACTS: {
      return {
        ...state,
        noContacts: {
          noEmail: true,
          noPhone: true,
        },
      };
    }

    case ACTIONS.HOTELS.SET_ADDITIONAL_COMMENT: {
      return {
        ...state,
        additionalComment: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_TEMPLATE_ID: {
      return {
        ...state,
        templateId: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_CUSTOMERS_LIST: {
      return {
        ...state,
        customersList: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_SELECTED_CUSTOMERS: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_NO_RATES: {
      return {
        ...state,
        hotelInfo: {
          ...state.hotelInfo,
          worksWithVat: false,
          priceInclVat: false,
        },
      };
    }

    case ACTIONS.HOTELS.UPDATE_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_IS_EDITING: {
      return {
        ...state,
        isEditing: action.payload,
      };
    }

    case ACTIONS.HOTELS.GET_EDITED_REQUEST: {
      return {
        ...state,
        editedRequest: action.payload,
      };
    }

    case ACTIONS.HOTELS.UPLOAD_CUSTOM_TEMPLATE_REQUEST: {
      return {
        ...state,
        customTemplate: {
          ...state.customTemplate,
          file: action.file,
          uploaded: true,
          failed: false,
        },
      };
    }

    case ACTIONS.HOTELS.UPLOAD_TEMPLATE_FAILED: {
      return {
        ...state,
        customTemplate: {
          uploaded: false,
          failed: true,
          error: FILE_UPLOAD_FAILED,
        },
      };
    }

    case ACTIONS.HOTELS.RESET_CUSTOM_TEMPLATE_UPLOAD: {
      return {
        ...state,
        customTemplate: {
          file: null,
          uploaded: true,
          failed: false,
          error: '',
        },
      };
    }

    case ACTIONS.HOTELS.SET_IS_SELF_EMPLOYED: {
      return {
        ...state,
        hotelInfo: {
          ...state.hotelInfo,
          isSelfEmployed: action.payload,
        },
      };
    }

    case ACTIONS.HOTELS.CHANGE_REQUEST_STATUS: {
      return {
        ...state,
        requestStatus: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_CUSTOMER_INFO: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_SELECTED_DEPARTMENT: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_CUSTOMER_PROJECT_ID: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.GET_MANDATORY_PROJECT: {
      return {
        ...state,
        MandatoryProject: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_CUSTOMER_PROJECTS: {
      return {
        ...state,
        customerProjects: action.payload,
      };
    }

    case ACTIONS.HOTELS.GET_ACCOUNT_ANALYTICS: {
      return {
        ...state,
        accountAnalytics: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_ACCOUNT_ANALYTICS: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_EMPLOYEE_LIST: {
      return {
        ...state,
        employeesList: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_HOTEL: {
      return {
        ...state,
        hotel: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_TRIP_ID: {
      return {
        ...state,
        tripId: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_LETTER_SUBJECT: {
      return {
        ...state,
        letterSubject: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_COMMISSION: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_HOTEL_ID: {
      return {
        ...state,
        hotelId: action.payload,
      };
    }

    case ACTIONS.HOTELS.CHANGE_MATCH_ID: {
      const { field, value } = action.payload;

      return {
        ...state,
        matchProvidersId: {
          ...state.matchProvidersId,
          [field]: value,
        },
      };
    }

    case ACTIONS.HOTELS.ERROR_MATCH: {
      return {
        ...state,
        errorMatch: action.payload,
      };
    }

    case ACTIONS.HOTELS.MATCHED_HOTELS: {
      return {
        ...state,
        matchHotels: action.payload,
      };
    }

    case ACTIONS.HOTELS.LOADING_MATCHED_HOTELS: {
      return {
        ...state,
        loadingMatchHotels: action.payload,
      };
    }

    case ACTIONS.HOTELS.RESET_MATCHES: {
      return {
        ...state,
        matchProvidersId: DEFAULT_MATCH_HOTEL_IDS,
        errorMatch: '',
        matchHotels: DEFAULT_MATCHES_HOTELS,
      };
    }

    case ACTIONS.HOTELS.SET_COMPARES_ALL_MATCHES: {
      return {
        ...state,
        comparesAllMatches: action.payload,
        isHotelWithCompare: true,
      };
    }

    case ACTIONS.HOTELS.LOADING_RESTORE_HOTEL: {
      return {
        ...state,
        loadingRestoreHotel: action.payload,
      };
    }

    case ACTIONS.HOTELS.SHOW_COMPARES_DIALOG: {
      return {
        ...state,
        showComparesDialog: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_GIATA_MAPPING: {
      return {
        ...state,
        mappingGiata: action.payload.Mappings,
      };
    }

    case ACTIONS.HOTELS.SET_USERS: {
      return {
        ...state,
        requestUsers: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_REQUEST_USER_ID: {
      return {
        ...state,
        requestUserId: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_CREATE_TRIP_SUCCESS: {
      return {
        ...state,
        newTripSuccess: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_CREATE_TRIP_FAILURE: {
      return {
        ...state,
        newTripFailure: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_NO_HOTEL_FOUND: {
      return {
        ...state,
        hotelNotFound: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_CART_ID: {
      return {
        ...state,
        cartId: action.payload,
      };
    }

    case ACTIONS.HOTELS.ADD_NEW_ROOM: {
      return {
        ...state,
        hotelRates: [...state.hotelRates, action.payload],
      };
    }

    case ACTIONS.HOTELS.DELETE_ROOM: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.COPY_ROOM: {
      return {
        ...state,
        hotelRates: [...state.hotelRates, action.payload],
      };
    }

    case ACTIONS.HOTELS.CLEAR_ROOMS: {
      return {
        ...state,
        hotelRates: [],
      };
    }

    case ACTIONS.HOTELS.CHANGE_HOTEL_RATE_ID: {
      return {
        ...state,
        hotelRates: action.payload,
      };
    }

    case ACTIONS.HOTELS.SET_REQUEST_ANALYTICS: {
      return {
        ...state,
        selectedRequestAnalytics: action.payload,
      };
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('Hotels', reducer, defaultStore);

  const createStore = () => new Store('Hotels', reducer, defaultStore);

  return createStore();
}
